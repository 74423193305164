// Set Global URLs For Production and Testing Based on Release
export default {
    ADMIN_API: "https://adminapis.digicollect.com",
    // ADMIN_API: "https://testadminapis.digicollect.com",
    AUTH_URL: "https://admin.digicollect.com",
    // AUTH_URL: "https://testadmin.digicollect.com",
    CRM_URL: "https://crm.digicollect.com",
    // CRM_URL: "https://testcrm.digicollect.com",
    CRM_API: "https://crmapis.digicollect.com",
    // CRM_API: "https://testcrmapis.digicollect.com",
    CRM_SALES_URL: "https://crmsalesapis.digicollect.com",
    // CRM_SALES_URL: "https://testcrmsalesapis.digicollect.com",
    AUTH_SERVICE: "https://adminapis.digicollect.com",
    // AUTH_SERVICE: "https://testadminapis.digicollect.com",
    POS_API: "https://posapis.digicollect.com",
    // POS_API: "https://testposapis.digicollect.com",
    SCM_API: "https://scmapis.digicollect.com",
    // SCM_API: "https://testscmapis.digicollect.com",
    CLOUD_API: "https://cloudapis.digicollect.com",
    // CLOUD_API: "https://testcloudapis.digicollect.com",
    EXPENSE_API: "https://expenseapis.digicollect.com",
    // EXPENSE_API: "https://testexpenseapis.digicollect.com",
    EXPENSE_URL: "https://expense.digicollect.com",
    // EXPENSE_URL: "https://testexpense.digicollect.com",
  };
